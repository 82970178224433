import React from "react";
import PageTitle from "components/layout/PageTitle";
import { useParams } from "helpers/tixxt-router";
import ToggleChannelFavoriteButton from "components/shared/favoriteButtons/ToggleChannelFavoriteButton";
import { updateChannelSubscription, useChannel } from "components/channels/api";
import { useChannelLayout } from "components/channels/layout/api";
import DropDown, { DropDownItem } from "components/shared/DropDown";
import { ChannelLayout } from "./ChannelLayout";
import { isString } from "lodash";
import Hint from "components/shared/fields/Hint";
import { faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import useChannelSubscriptionToggle from "components/shared/hooks/useChannelSubscriptionToggle";

export default function ChannelPage({
  isAdministration = false,
}: {
  isAdministration?: boolean;
}) {
  const { channelSlug } = useParams();
  const { data: channel } = useChannel(channelSlug);
  const { data: layoutData, isLoading: layoutLoading } =
    useChannelLayout(channelSlug);
  const hasChangesString = sessionStorage.getItem(`${channelSlug}:hasChanges`);

  const hasChanges = isString(hasChangesString) && JSON.parse(hasChangesString);

  const dropdownItems: React.JSX.Element[] = [];

  const { subscribeChannel, unsubscribeChannel } = useChannelSubscriptionToggle(
    {
      channelSlug: channelSlug || "",
      onSuccessFunction: (subscribed) => {
        channelSlug && updateChannelSubscription(channelSlug, subscribed);
      },
    },
  );

  dropdownItems.push(
    <DropDownItem
      key="show_blog"
      text={I18n.t("js.channels.title.dropdown.show_blog")}
      url={`/blogs/${channel?.blog_id}`}
    />,
    <DropDownItem
      key="subscription"
      text={
        channel?.i.subscribed
          ? I18n.t("js.directory.channel_row.unsubscribe.title")
          : I18n.t("js.directory.channel_row.subscribe.title")
      }
      onSelect={() => {
        channel?.i.subscribed ? unsubscribeChannel() : subscribeChannel();
      }}
    />,
  );

  if (channel?.i.can.update && !isAdministration)
    dropdownItems.push(
      <DropDownItem
        key="update"
        text={I18n.t("js.channels.title.dropdown.edit_channel")}
        url={`/channels/${channel.slug}/edit`}
        dividerTop
      />,
      <DropDownItem
        key="edit_layout"
        text={I18n.t("js.channels.title.dropdown.edit_channel_layout")}
        url={`/channels/${channel.slug}/layout/edit`}
      />,
      <DropDownItem
        key="add_article"
        text={I18n.t("js.channels.title.dropdown.add_article")}
        url={`/blogs/${channel.blog_id}/articles/new`}
      />,
    );

  return (
    <>
      {(channel?.status === "draft" || channel?.status === "archived") &&
      !channel?.i.can.update ? (
        <p>{I18n.t(`js.channels.${channel.status}.body`)}</p>
      ) : (
        <>
          {hasChanges && (
            <div className="alert alert-info">
              <Hint
                hint={I18n.t("js.channels.layout.not_saved_hint")}
                className="!mb-0"
              />
            </div>
          )}
          <PageTitle title={channel?.title || ""}>
            {channel && !isAdministration && (
              <div className={"flex place-items-center"}>
                {dropdownItems.length > 0 && (
                  <>
                    <DropDown.Toggle
                      icon={faEllipsis}
                      buttonType="normal"
                      small
                    />
                    <DropDown alignment="start">{dropdownItems}</DropDown>
                  </>
                )}
                {channel && channel.i.subscribed && (
                  <ToggleChannelFavoriteButton
                    channelSlug={channel.slug}
                    favorited={channel.i.favorited}
                  />
                )}
              </div>
            )}
          </PageTitle>
          <ChannelLayout
            layoutData={layoutData}
            channelSlug={channel?.id}
            isLoading={layoutLoading}
          />
        </>
      )}
    </>
  );
}
