/**
 * Do not edit this file manually!
 *
 * It is generated by scripts/dev/generate_fontawesome_manifest.rb
 * Use something like class="fa-solid fa-star" in a view/template/component and rerun the script
 */

import { library, dom, config } from "@fortawesome/fontawesome-svg-core";
import {
  faAdjust as faSolidAdjust,
  faAlignJustify as faSolidAlignJustify,
  faAngleDoubleDown as faSolidAngleDoubleDown,
  faAngleDoubleLeft as faSolidAngleDoubleLeft,
  faAngleDoubleRight as faSolidAngleDoubleRight,
  faAngleDoubleUp as faSolidAngleDoubleUp,
  faAngleLeft as faSolidAngleLeft,
  faAngleRight as faSolidAngleRight,
  faArchive as faSolidArchive,
  faArrowCircleUp as faSolidArrowCircleUp,
  faArrowDown as faSolidArrowDown,
  faArrowLeft as faSolidArrowLeft,
  faArrowRight as faSolidArrowRight,
  faArrows as faSolidArrows,
  faArrowsAlt as faSolidArrowsAlt,
  faAt as faSolidAt,
  faBan as faSolidBan,
  faBarChart as faSolidBarChart,
  faBars as faSolidBars,
  faBold as faSolidBold,
  faBook as faSolidBook,
  faBug as faSolidBug,
  faBullhorn as faSolidBullhorn,
  faCalendar as faSolidCalendar,
  faCaretDown as faSolidCaretDown,
  faCaretLeft as faSolidCaretLeft,
  faCaretRight as faSolidCaretRight,
  faCertificate as faSolidCertificate,
  faCheck as faSolidCheck,
  faCheckSquare as faSolidCheckSquare,
  faChevronCircleDown as faSolidChevronCircleDown,
  faChevronDown as faSolidChevronDown,
  faChevronLeft as faSolidChevronLeft,
  faChevronRight as faSolidChevronRight,
  faChevronUp as faSolidChevronUp,
  faCircle as faSolidCircle,
  faCircleInfo as faSolidCircleInfo,
  faCircleQuestion as faSolidCircleQuestion,
  faClone as faSolidClone,
  faCloudDownload as faSolidCloudDownload,
  faCode as faSolidCode,
  faCodeFork as faSolidCodeFork,
  faCoffee as faSolidCoffee,
  faCog as faSolidCog,
  faCogs as faSolidCogs,
  faColumns as faSolidColumns,
  faComment as faSolidComment,
  faCompress as faSolidCompress,
  faEllipsis as faSolidEllipsis,
  faEllipsisH as faSolidEllipsisH,
  faEnvelope as faSolidEnvelope,
  faExclamation as faSolidExclamation,
  faExpand as faSolidExpand,
  faExternalLink as faSolidExternalLink,
  faEye as faSolidEye,
  faEyeSlash as faSolidEyeSlash,
  faFile as faSolidFile,
  faFileCsv as faSolidFileCsv,
  faFileExcel as faSolidFileExcel,
  faFilter as faSolidFilter,
  faFlag as faSolidFlag,
  faFlagCheckered as faSolidFlagCheckered,
  faFolder as faSolidFolder,
  faFolderOpen as faSolidFolderOpen,
  faFont as faSolidFont,
  faGlobe as faSolidGlobe,
  faGripDots as faSolidGripDots,
  faHashtag as faSolidHashtag,
  faHeader as faSolidHeader,
  faHome as faSolidHome,
  faImage as faSolidImage,
  faInfoCircle as faSolidInfoCircle,
  faItalic as faSolidItalic,
  faLink as faSolidLink,
  faLinkSlash as faSolidLinkSlash,
  faList as faSolidList,
  faListAlt as faSolidListAlt,
  faListOl as faSolidListOl,
  faListUl as faSolidListUl,
  faLock as faSolidLock,
  faMailbox as faSolidMailbox,
  faMapMarker as faSolidMapMarker,
  faMessageXmark as faSolidMessageXmark,
  faPaperPlane as faSolidPaperPlane,
  faPaperclip as faSolidPaperclip,
  faPencil as faSolidPencil,
  faPencilSquare as faSolidPencilSquare,
  faPipe as faSolidPipe,
  faPlay as faSolidPlay,
  faPlus as faSolidPlus,
  faPlusCircle as faSolidPlusCircle,
  faPrint as faSolidPrint,
  faQuestion as faSolidQuestion,
  faQuestionCircle as faSolidQuestionCircle,
  faQuoteLeft as faSolidQuoteLeft,
  faQuoteRight as faSolidQuoteRight,
  faRefresh as faSolidRefresh,
  faReply as faSolidReply,
  faReplyAll as faSolidReplyAll,
  faSave as faSolidSave,
  faSearch as faSolidSearch,
  faShare as faSolidShare,
  faShield as faSolidShield,
  faSort as faSolidSort,
  faSortAsc as faSolidSortAsc,
  faSortDesc as faSolidSortDesc,
  faSortDown as faSolidSortDown,
  faSortNumericAsc as faSolidSortNumericAsc,
  faSortUp as faSolidSortUp,
  faSpaceShuttle as faSolidSpaceShuttle,
  faSpinner as faSolidSpinner,
  faSquare as faSolidSquare,
  faSquareO as faSolidSquareO,
  faStar as faSolidStar,
  faStepBackward as faSolidStepBackward,
  faStepForward as faSolidStepForward,
  faStrikethrough as faSolidStrikethrough,
  faTag as faSolidTag,
  faTags as faSolidTags,
  faThumbsUp as faSolidThumbsUp,
  faThumbtack as faSolidThumbtack,
  faTimes as faSolidTimes,
  faTimesCircle as faSolidTimesCircle,
  faToggleOff as faSolidToggleOff,
  faToggleOn as faSolidToggleOn,
  faTrash as faSolidTrash,
  faUnderline as faSolidUnderline,
  faUnlock as faSolidUnlock,
  faUnlockAlt as faSolidUnlockAlt,
  faUser as faSolidUser,
  faUserCircle as faSolidUserCircle,
  faUserMinus as faSolidUserMinus,
  faUserPlus as faSolidUserPlus,
  faUserSecret as faSolidUserSecret,
  faUserTimes as faSolidUserTimes,
  faUserXmark as faSolidUserXmark,
  faUsers as faSolidUsers,
  faVolumeXmark as faSolidVolumeXmark,
  faXmark as faSolidXmark,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faDailymotion as faBrandsDailymotion,
  faDeviantart as faBrandsDeviantart,
  faFacebook as faBrandsFacebook,
  faFlickr as faBrandsFlickr,
  faInstagram as faBrandsInstagram,
  faKickstarter as faBrandsKickstarter,
  faMicrosoft as faBrandsMicrosoft,
  faSlideshare as faBrandsSlideshare,
  faSoundcloud as faBrandsSoundcloud,
  faSpotify as faBrandsSpotify,
  faTwitter as faBrandsTwitter,
  faVimeo as faBrandsVimeo,
  faYoutube as faBrandsYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAlignCenter as faRegularAlignCenter,
  faAlignLeft as faRegularAlignLeft,
  faAlignRight as faRegularAlignRight,
  faAngleLeft as faRegularAngleLeft,
  faAngleRight as faRegularAngleRight,
  faArrowDownToBracket as faRegularArrowDownToBracket,
  faArrowLeft as faRegularArrowLeft,
  faArrows as faRegularArrows,
  faArrowsRepeat as faRegularArrowsRepeat,
  faArrowsUpDownLeftRight as faRegularArrowsUpDownLeftRight,
  faBars as faRegularBars,
  faBold as faRegularBold,
  faBolt as faRegularBolt,
  faBookmark as faRegularBookmark,
  faBoxArchive as faRegularBoxArchive,
  faBullhorn as faRegularBullhorn,
  faCalendar as faRegularCalendar,
  faCalendarDay as faRegularCalendarDay,
  faCalendarPlus as faRegularCalendarPlus,
  faCalendarRange as faRegularCalendarRange,
  faChartColumn as faRegularChartColumn,
  faCheck as faRegularCheck,
  faCheckCircle as faRegularCheckCircle,
  faCheckSquare as faRegularCheckSquare,
  faChevronDown as faRegularChevronDown,
  faChevronUp as faRegularChevronUp,
  faCircle as faRegularCircle,
  faCircleCheck as faRegularCircleCheck,
  faCircleInfo as faRegularCircleInfo,
  faCircleNotch as faRegularCircleNotch,
  faCirclePlay as faRegularCirclePlay,
  faCirclePlus as faRegularCirclePlus,
  faCircleUser as faRegularCircleUser,
  faClipboard as faRegularClipboard,
  faClock as faRegularClock,
  faClone as faRegularClone,
  faCloudDownload as faRegularCloudDownload,
  faCloudUpload as faRegularCloudUpload,
  faComment as faRegularComment,
  faCommentDots as faRegularCommentDots,
  faCommentSlash as faRegularCommentSlash,
  faComments as faRegularComments,
  faComputerClassic as faRegularComputerClassic,
  faDesktop as faRegularDesktop,
  faEarthEurope as faRegularEarthEurope,
  faEdit as faRegularEdit,
  faEllipsis as faRegularEllipsis,
  faEllipsisH as faRegularEllipsisH,
  faEllipsisVertical as faRegularEllipsisVertical,
  faEnvelope as faRegularEnvelope,
  faExchange as faRegularExchange,
  faEye as faRegularEye,
  faFile as faRegularFile,
  faFileCode as faRegularFileCode,
  faFileExcel as faRegularFileExcel,
  faFilePowerpoint as faRegularFilePowerpoint,
  faFileText as faRegularFileText,
  faFileWord as faRegularFileWord,
  faFiles as faRegularFiles,
  faFilter as faRegularFilter,
  faFloppyDisk as faRegularFloppyDisk,
  faFolder as faRegularFolder,
  faFolderOpen as faRegularFolderOpen,
  faHandshake as faRegularHandshake,
  faImage as faRegularImage,
  faInbox as faRegularInbox,
  faItalic as faRegularItalic,
  faKeyboard as faRegularKeyboard,
  faLink as faRegularLink,
  faList as faRegularList,
  faListOl as faRegularListOl,
  faListUl as faRegularListUl,
  faLocationDot as faRegularLocationDot,
  faLock as faRegularLock,
  faMailbox as faRegularMailbox,
  faMapLocation as faRegularMapLocation,
  faMapLocationDot as faRegularMapLocationDot,
  faMessages as faRegularMessages,
  faMinusCircle as faRegularMinusCircle,
  faMobile as faRegularMobile,
  faPaperclipVertical as faRegularPaperclipVertical,
  faPenToSquare as faRegularPenToSquare,
  faPencil as faRegularPencil,
  faPencilSquare as faRegularPencilSquare,
  faPipe as faRegularPipe,
  faPlus as faRegularPlus,
  faPlusCircle as faRegularPlusCircle,
  faPlusSquare as faRegularPlusSquare,
  faPowerOff as faRegularPowerOff,
  faScissors as faRegularScissors,
  faSearch as faRegularSearch,
  faShareSquare as faRegularShareSquare,
  faSidebar as faRegularSidebar,
  faSpinner as faRegularSpinner,
  faSquare as faRegularSquare,
  faStar as faRegularStar,
  faSuitcaseRolling as faRegularSuitcaseRolling,
  faTable as faRegularTable,
  faTags as faRegularTags,
  faTerminal as faRegularTerminal,
  faThumbsUp as faRegularThumbsUp,
  faTimes as faRegularTimes,
  faTimesCircle as faRegularTimesCircle,
  faTrash as faRegularTrash,
  faTrashCan as faRegularTrashCan,
  faTriangleExclamation as faRegularTriangleExclamation,
  faUnderline as faRegularUnderline,
  faUnlock as faRegularUnlock,
  faUpload as faRegularUpload,
  faUser as faRegularUser,
  faUserCircle as faRegularUserCircle,
  faUserPlus as faRegularUserPlus,
  faUserXmark as faRegularUserXmark,
  faUsers as faRegularUsers,
  faVirus as faRegularVirus,
  faVolumeXmark as faRegularVolumeXmark,
  faXmark as faRegularXmark,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCloudArrowDown as faLightCloudArrowDown,
  faEnvelope as faLightEnvelope,
  faFile as faLightFile,
  faFileAudio as faLightFileAudio,
  faFileCode as faLightFileCode,
  faFileCsv as faLightFileCsv,
  faFileExcel as faLightFileExcel,
  faFileImage as faLightFileImage,
  faFileLines as faLightFileLines,
  faFilePdf as faLightFilePdf,
  faFilePowerpoint as faLightFilePowerpoint,
  faFileVideo as faLightFileVideo,
  faFileWord as faLightFileWord,
  faFileZipper as faLightFileZipper,
  faImage as faLightImage,
  faMobileScreenButton as faLightMobileScreenButton,
  faPaperclipVertical as faLightPaperclipVertical,
} from "@fortawesome/pro-light-svg-icons";
import {
  faChartSimple as faDuotoneChartSimple,
  faHandshakeAngle as faDuotoneHandshakeAngle,
  faMedal as faDuotoneMedal,
  faMegaphone as faDuotoneMegaphone,
  faPaperPlane as faDuotonePaperPlane,
  faRss as faDuotoneRss,
  faUsers as faDuotoneUsers,
} from "@fortawesome/pro-duotone-svg-icons";

config.autoReplaceSvg = "nest";

library.add(
  faBrandsDailymotion,
  faBrandsDeviantart,
  faBrandsFacebook,
  faBrandsFlickr,
  faBrandsInstagram,
  faBrandsKickstarter,
  faBrandsMicrosoft,
  faBrandsSlideshare,
  faBrandsSoundcloud,
  faBrandsSpotify,
  faBrandsTwitter,
  faBrandsVimeo,
  faBrandsYoutube,
  faDuotoneChartSimple,
  faDuotoneHandshakeAngle,
  faDuotoneMedal,
  faDuotoneMegaphone,
  faDuotonePaperPlane,
  faDuotoneRss,
  faDuotoneUsers,
  faLightCloudArrowDown,
  faLightEnvelope,
  faLightFile,
  faLightFileAudio,
  faLightFileCode,
  faLightFileCsv,
  faLightFileExcel,
  faLightFileImage,
  faLightFileLines,
  faLightFilePdf,
  faLightFilePowerpoint,
  faLightFileVideo,
  faLightFileWord,
  faLightFileZipper,
  faLightImage,
  faLightMobileScreenButton,
  faLightPaperclipVertical,
  faRegularAlignCenter,
  faRegularAlignLeft,
  faRegularAlignRight,
  faRegularAngleLeft,
  faRegularAngleRight,
  faRegularArrowDownToBracket,
  faRegularArrowLeft,
  faRegularArrows,
  faRegularArrowsRepeat,
  faRegularArrowsUpDownLeftRight,
  faRegularBars,
  faRegularBold,
  faRegularBolt,
  faRegularBookmark,
  faRegularBoxArchive,
  faRegularBullhorn,
  faRegularCalendar,
  faRegularCalendarDay,
  faRegularCalendarPlus,
  faRegularCalendarRange,
  faRegularChartColumn,
  faRegularCheck,
  faRegularCheckCircle,
  faRegularCheckSquare,
  faRegularChevronDown,
  faRegularChevronUp,
  faRegularCircle,
  faRegularCircleCheck,
  faRegularCircleInfo,
  faRegularCircleNotch,
  faRegularCirclePlay,
  faRegularCirclePlus,
  faRegularCircleUser,
  faRegularClipboard,
  faRegularClock,
  faRegularClone,
  faRegularCloudDownload,
  faRegularCloudUpload,
  faRegularComment,
  faRegularCommentDots,
  faRegularCommentSlash,
  faRegularComments,
  faRegularComputerClassic,
  faRegularDesktop,
  faRegularEarthEurope,
  faRegularEdit,
  faRegularEllipsis,
  faRegularEllipsisH,
  faRegularEllipsisVertical,
  faRegularEnvelope,
  faRegularExchange,
  faRegularEye,
  faRegularFile,
  faRegularFileCode,
  faRegularFileExcel,
  faRegularFilePowerpoint,
  faRegularFileText,
  faRegularFileWord,
  faRegularFiles,
  faRegularFilter,
  faRegularFloppyDisk,
  faRegularFolder,
  faRegularFolderOpen,
  faRegularHandshake,
  faRegularImage,
  faRegularInbox,
  faRegularItalic,
  faRegularKeyboard,
  faRegularLink,
  faRegularList,
  faRegularListOl,
  faRegularListUl,
  faRegularLocationDot,
  faRegularLock,
  faRegularMailbox,
  faRegularMapLocation,
  faRegularMapLocationDot,
  faRegularMessages,
  faRegularMinusCircle,
  faRegularMobile,
  faRegularPaperclipVertical,
  faRegularPenToSquare,
  faRegularPencil,
  faRegularPencilSquare,
  faRegularPipe,
  faRegularPlus,
  faRegularPlusCircle,
  faRegularPlusSquare,
  faRegularPowerOff,
  faRegularScissors,
  faRegularSearch,
  faRegularShareSquare,
  faRegularSidebar,
  faRegularSpinner,
  faRegularSquare,
  faRegularStar,
  faRegularSuitcaseRolling,
  faRegularTable,
  faRegularTags,
  faRegularTerminal,
  faRegularThumbsUp,
  faRegularTimes,
  faRegularTimesCircle,
  faRegularTrash,
  faRegularTrashCan,
  faRegularTriangleExclamation,
  faRegularUnderline,
  faRegularUnlock,
  faRegularUpload,
  faRegularUser,
  faRegularUserCircle,
  faRegularUserPlus,
  faRegularUserXmark,
  faRegularUsers,
  faRegularVirus,
  faRegularVolumeXmark,
  faRegularXmark,
  faSolidAdjust,
  faSolidAlignJustify,
  faSolidAngleDoubleDown,
  faSolidAngleDoubleLeft,
  faSolidAngleDoubleRight,
  faSolidAngleDoubleUp,
  faSolidAngleLeft,
  faSolidAngleRight,
  faSolidArchive,
  faSolidArrowCircleUp,
  faSolidArrowDown,
  faSolidArrowLeft,
  faSolidArrowRight,
  faSolidArrows,
  faSolidArrowsAlt,
  faSolidAt,
  faSolidBan,
  faSolidBarChart,
  faSolidBars,
  faSolidBold,
  faSolidBook,
  faSolidBug,
  faSolidBullhorn,
  faSolidCalendar,
  faSolidCaretDown,
  faSolidCaretLeft,
  faSolidCaretRight,
  faSolidCertificate,
  faSolidCheck,
  faSolidCheckSquare,
  faSolidChevronCircleDown,
  faSolidChevronDown,
  faSolidChevronLeft,
  faSolidChevronRight,
  faSolidChevronUp,
  faSolidCircle,
  faSolidCircleInfo,
  faSolidCircleQuestion,
  faSolidClone,
  faSolidCloudDownload,
  faSolidCode,
  faSolidCodeFork,
  faSolidCoffee,
  faSolidCog,
  faSolidCogs,
  faSolidColumns,
  faSolidComment,
  faSolidCompress,
  faSolidEllipsis,
  faSolidEllipsisH,
  faSolidEnvelope,
  faSolidExclamation,
  faSolidExpand,
  faSolidExternalLink,
  faSolidEye,
  faSolidEyeSlash,
  faSolidFile,
  faSolidFileCsv,
  faSolidFileExcel,
  faSolidFilter,
  faSolidFlag,
  faSolidFlagCheckered,
  faSolidFolder,
  faSolidFolderOpen,
  faSolidFont,
  faSolidGlobe,
  faSolidGripDots,
  faSolidHashtag,
  faSolidHeader,
  faSolidHome,
  faSolidImage,
  faSolidInfoCircle,
  faSolidItalic,
  faSolidLink,
  faSolidLinkSlash,
  faSolidList,
  faSolidListAlt,
  faSolidListOl,
  faSolidListUl,
  faSolidLock,
  faSolidMailbox,
  faSolidMapMarker,
  faSolidMessageXmark,
  faSolidPaperPlane,
  faSolidPaperclip,
  faSolidPencil,
  faSolidPencilSquare,
  faSolidPipe,
  faSolidPlay,
  faSolidPlus,
  faSolidPlusCircle,
  faSolidPrint,
  faSolidQuestion,
  faSolidQuestionCircle,
  faSolidQuoteLeft,
  faSolidQuoteRight,
  faSolidRefresh,
  faSolidReply,
  faSolidReplyAll,
  faSolidSave,
  faSolidSearch,
  faSolidShare,
  faSolidShield,
  faSolidSort,
  faSolidSortAsc,
  faSolidSortDesc,
  faSolidSortDown,
  faSolidSortNumericAsc,
  faSolidSortUp,
  faSolidSpaceShuttle,
  faSolidSpinner,
  faSolidSquare,
  faSolidSquareO,
  faSolidStar,
  faSolidStepBackward,
  faSolidStepForward,
  faSolidStrikethrough,
  faSolidTag,
  faSolidTags,
  faSolidThumbsUp,
  faSolidThumbtack,
  faSolidTimes,
  faSolidTimesCircle,
  faSolidToggleOff,
  faSolidToggleOn,
  faSolidTrash,
  faSolidUnderline,
  faSolidUnlock,
  faSolidUnlockAlt,
  faSolidUser,
  faSolidUserCircle,
  faSolidUserMinus,
  faSolidUserPlus,
  faSolidUserSecret,
  faSolidUserTimes,
  faSolidUserXmark,
  faSolidUsers,
  faSolidVolumeXmark,
  faSolidXmark,
);

dom.watch();
