import React, { createContext, CSSProperties, useRef } from "react";
import classNames from "classnames";
import "./widgets.css";
import readableTextColor from "helpers/readableTextColor";
import Loading from "components/shared/Loading";
import {
  getWidgetColspan,
  getWidgetContentHeight,
  getWidgetContainerResponsiveHeight,
} from "components/channels/helpers";

type Props = {
  title: string;
  titleBackgroundOverride: string | null;
  className?: string;
  w: number; // number of colums this widget occupies (1-3)
  children?: React.ReactNode;
  loading?: boolean;
  contentClassName?: string;
  style?: CSSProperties;
  label?: null | "current" | "spotlight" | "important";
  collapseToSingleColumn?: boolean;
  singleRowOnly?: boolean;
  hideTitle?: boolean;
};

export const WidgetContext = createContext<{
  w: number;
  containerRef: React.RefObject<HTMLDivElement> | null;
}>({ w: 1, containerRef: null });

export default function WidgetContainer({
  title,
  titleBackgroundOverride,
  hideTitle = false,
  w,
  children,
  loading,
  className,
  contentClassName,
  style,
  label,
  collapseToSingleColumn,
  singleRowOnly,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  if (collapseToSingleColumn && window.innerWidth < 640) w = 1;

  return (
    <WidgetContext.Provider value={{ w, containerRef }}>
      <div
        ref={containerRef}
        className={classNames(
          "widget__container",
          w && getWidgetColspan(w),
          w && getWidgetContainerResponsiveHeight(singleRowOnly ? 1 : w),
          className,
        )}
        style={style}
      >
        {!hideTitle && (
          <div
            className={
              "widget__title flex items-center px-4 font-semibold text-sm rounded-tl rounded-tr gap-2"
            }
            style={
              titleBackgroundOverride
                ? {
                    backgroundColor: titleBackgroundOverride,
                    color:
                      readableTextColor(titleBackgroundOverride) ||
                      "var(--normal-text)",
                  }
                : undefined
            }
          >
            {label && (
              <span className={`widget__label widget__label--${label}`}>
                {I18n.t(`js.channels.widgets.labels.${label}`)}
              </span>
            )}
            <span>{title}</span>
          </div>
        )}
        <div
          className={classNames(
            "widget__content bg-white rounded-bl rounded-br grid",
            getWidgetContentHeight(w),
            contentClassName,
          )}
        >
          {loading ? <Loading /> : null}
          {children}
        </div>
      </div>
    </WidgetContext.Provider>
  );
}
