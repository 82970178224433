import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEventHandler, useEffect } from "react";
import classnames from "classnames";
import { faChevronRight, faEllipsis } from "@fortawesome/pro-regular-svg-icons";

type DropDownArgs = {
  children: React.ReactNode;
  alignment?: "start" | "end";
  className?: string;
};

function DropDown({ children, className, alignment = "end" }: DropDownArgs) {
  // make sure to close dropdowns on body click
  useEffect(() => {
    const handleBodyClick = () => {
      const elements = document.querySelectorAll(
        ".tixxt__dropdown.dropdown-menu",
      );
      elements.forEach((element) => {
        element.classList.remove("show");
      });
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  return (
    <ul
      className={classnames(
        `tixxt__dropdown dropdown-menu dropdown-menu-${alignment} empty:hidden`,
        className,
      )}
    >
      {children}
    </ul>
  );
}

type DropDownItemArgs = {
  children?: React.ReactNode;
  url?: string;
  icon?: IconProp;
  text?: string;
  additional?: React.ReactNode;
  linkStyle?: string;
  iconStyle?: React.CSSProperties;
  method?: string;
  confirm?: string;
  className?: string;
  iconClassName?: string;
  onSelect?: MouseEventHandler<HTMLAnchorElement>;
  title?: string;
  dividerTop?: boolean;
  dividerBottom?: boolean;
};

export function DropDownItem({
  children,
  url,
  icon,
  text,
  additional,
  linkStyle,
  iconStyle,
  method,
  confirm,
  className,
  iconClassName,
  onSelect,
  title,
  dividerTop,
  dividerBottom,
}: DropDownItemArgs) {
  return (
    <>
      {dividerTop && <li className="divider" />}
      <li className={className} title={title || text}>
        {children ? (
          children
        ) : (
          <a
            href={url || "#"}
            className={classNames("dropdown-item", linkStyle)}
            data-method={method}
            data-confirm={confirm}
            onClick={(e) => {
              if (onSelect) {
                e.stopPropagation();
                onSelect(e);
              }
            }}
          >
            <span className="flex items-center space-x-1">
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  className={classNames("fa-fw", iconClassName)}
                  style={iconStyle}
                />
              )}
              {text && <span className="flex-1">{text}</span>}
              {additional}
            </span>
          </a>
        )}
      </li>
      {dividerBottom && <li className="divider" />}
    </>
  );
}

type DropDownSubMenuArgs = {
  text: string;
  children: React.ReactNode;
  className?: string;
  icon?: IconProp;
  iconClassName?: string;
  side?: "left" | "right";
  dividerTop?: boolean;
  dividerBottom?: boolean;
};

export function DropDownSubMenu({
  text,
  children,
  className,
  icon,
  side = "right",
  dividerTop,
  dividerBottom,
}: DropDownSubMenuArgs) {
  return (
    <>
      {dividerTop && <li className="divider" />}
      <li className={classnames("relative group", className)}>
        <div className="dropdown-item flex gap-2 items-center w-full text-left cursor-pointer">
          <span className="flex items-center space-x-1">
            {icon && <FontAwesomeIcon icon={icon} className="fa-fw" />}
            <span className="flex-1">{text}</span>
          </span>
          <FontAwesomeIcon icon={faChevronRight} className="ml-auto" />
        </div>
        <ul
          className={classNames(
            "dropdown-menu absolute top-0 mt-0 hidden group-hover:block",
            {
              "left-full ml-1": side == "right",
              "right-full mr-1": side == "left",
            },
          )}
        >
          {children}
        </ul>
      </li>
      {dividerBottom && <li className="divider" />}
    </>
  );
}

type DropdownToggleArgs = {
  title?: string;
  active?: boolean;
  disabled?: boolean;
  small?: boolean;
  icon?: IconProp;
  className?: string;
  iconClassName?: string;
  buttonType?: "light" | "primary" | "danger" | "normal";
  children?: React.ReactNode;
};

export function DropDownToggle({
  active = false,
  disabled = false,
  small = false,
  title,
  icon,
  className,
  buttonType = "light",
  iconClassName,
  children,
}: DropdownToggleArgs) {
  return (
    <button
      className={classNames("btn dropdown-toggle", className, {
        active,
        "btn-light": buttonType === "light",
        "btn-primary": buttonType === "primary",
        "btn-danger": buttonType === "danger",
        "btn-sm": small,
      })}
      data-bs-toggle="dropdown"
      title={title}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
    >
      <FontAwesomeIcon className={iconClassName} icon={icon || faEllipsis} />
      {children}
    </button>
  );
}

DropDown.Item = DropDownItem;
DropDown.SubMenu = DropDownSubMenu;
DropDown.Toggle = DropDownToggle;
export default DropDown;
